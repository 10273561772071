import React from 'react'
import { Routes, Route } from "react-router-dom"
import Homepage from './pages/Homepage'
import MyNavbar from './components/navbar/Navbar'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import ParticlesBackground from './components/ParticlesBackground'
import "bootstrap/dist/css/bootstrap.min.css";


const App = () => {
  return (
    <div>
      <ParticlesBackground />
      <MyNavbar />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </div>
  )
}

export default App

