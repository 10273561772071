import React, { useState } from "react";
import "./Contact.css";
import { IoIosPhonePortrait } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import axios from "axios";

const Contact = () => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [responseError, setResponseError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage("");
    setResponseError("");
  
    // // Combine firstName and lastName into a single name field
    // const fullName = `${form.firstName} ${form.lastName}`;
  
    try {
      const response = await axios.post('https://api.sheetbest.com/sheets/aa67609c-b792-407c-a411-70c4e0ac09de', {
        "First Name": form.firstName,   // Using expected headers for the sheet
        "Last Name": form.lastName,
        "Email": form.email,
        "Phone": form.phone,
        "Message": form.message
      });
  
      if (response.status === 200) {
        setResponseMessage("Form submitted successfully! We'll get back to you soon!");
        setForm({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setResponseError("There was an issue submitting the form. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setResponseError("Something went wrong, please try again.");
    }
  };

  return (
    <div className="contact-container">
      {/* Title and Description */}
      <div className="contact-header">
        <h1 className="contact-page-title">Contact Us</h1>
        {/* <p className="contact-page-text">
          Investist is the most experienced and leading fractional ownership platform.
        </p> */}
      </div>

      {/* Contact Information */}
      <div className="blue-bg-with-icons">
        <div className="contact-info">
          <div className="contact-item">
            <div className="contact-icon-container">
              <IoIosPhonePortrait className="contact-icon" />
            </div>
            <p>(+91) 7024166889</p>
            <span>Monday - Friday 9am to 5pm</span>
          </div>
          <div className="contact-item">
            <div className="contact-icon-container">
              <MdEmail className="contact-icon" />
            </div>
            <p>your.companions.co@gmail.com</p>
            <span>We will reply as soon as possible</span>
          </div>
          <div className="contact-item">
            <div className="contact-icon-container">
              <ImLocation2 className="contact-icon" />
            </div>
            <p>New Palasia, Indore</p>
            <span>G-2, Princess Center, Indore, M.P.</span>
          </div>
        </div>
      </div>

      {/* Contact Form */}
      <div className="white-bg-container">
        <div className="contact-form-container3">
          <div className="contact_form_text">
            <h2>
              How Can We <span>Help You?</span>
            </h2>
            <p>
              Contact us today for a <span>FREE consultation</span>.
              Fill out the form below to send us an email.
            </p>
          </div>
          <form className="contact-form3" onSubmit={handleSubmit}>
            <div className="form-row3">
              <div className="form-group3">
                <label>Your Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                  required
                />
                <p className="placeholder_text">First Name</p>
              </div>
              <div className="form-group3">
                <label>&nbsp;</label>
                <input
                  type="text"
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                  required
                />
                <p className="placeholder_text">Last Name</p>
              </div>
            </div>
            <div className="form-row3">
              <div className="form-group3">
                <label>Your Email <span className="text-danger">*</span></label>
                <input
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  required
                />
                <p className="placeholder_text">example@example.com</p>
              </div>
              <div className="form-group3">
                <label>Your Phone <span className="text-danger">*</span></label>
                <input
                  type="number"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                  required
                />
                <p className="placeholder_text">Enter valid phone number.</p>
              </div>
            </div>
            <div className="form-group3">
              <label>How we can help you? <span className="text-danger">*</span></label>
              <textarea
                name="message"
                value={form.message}
                onChange={handleChange}
                required
              />
            </div>
            <p className="privacy-text">
              By clicking "Submit", you authorize us to contact you via text or email.
            </p>
            <div className="submit_button_div">
              <button type="submit" className="submit_button3">Send Message</button>
            </div>
          </form>

          {responseMessage && (
            <div className="alert alert-success">{responseMessage}</div>
          )}
          {responseError && (
            <div className="alert alert-danger">{responseError}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
