import React from 'react';
import { motion } from 'framer-motion';

const benefits = [
    {
        title: "User Testimonials",
        icon: "🗣️",
        description: "Hear from beta testers about how Companion has transformed their career exploration."
    },
    {
        title: "Improved Job Readiness",
        icon: "📈",
        description: "Statistics show that students using Companion feel more prepared for job interviews and career fairs."
    },
    {
        title: "Stay Informed",
        icon: "🔔",
        description: "Get timely notifications about school events and career opportunities directly on your device."
    }
];

const BenefitsSection = () => {
    return (
        <div className="container mx-auto px-4 py-10">
            <h2 className="text-3xl font-bold text-center mb-6">Benefits of Using Companion</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {benefits.map((benefit, index) => (
                    <motion.div
                        key={index}
                        className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105"
                        initial={{ opacity: 0, y: 20 }} // Initial state
                        whileInView={{
                            opacity: 1, y: 0, transition: {
                                duration: 0.3, // Duration of the animation
                                ease: 'easeIn', // Custom easing function for smoothness
                                delay: index * 0.2 // Staggered delay for each benefit} }} 
                            }
                        }}
                        whileHover={{ scale: 1.03, transition: { ease: 'easeInOut', duration: 0.3 } }} // Scale on hover

                    >
                        <div className="text-5xl mb-4">{benefit.icon}</div>
                        <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                        <p className="text-gray-600">{benefit.description}</p>
                    </motion.div>
                ))}
            </div>
        </div >
    );
};

export default BenefitsSection;