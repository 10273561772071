import React from 'react'
import Content1 from '../components/content/Content1'
import FeaturesSection from '../components/FeatureSection/FeatureSection'
import BenefitsSection from '../components/Benifits/Benefits'
import ResourceHub from '../components/Resources/Resource'
import DownloadInstructions from '../components/downloadInstruction/DownloadInstruction'
import FAQSection from '../components/Faqs/Faqs'


const Homepage = () => {
  return (
    <div>
      <Content1 />
      <FeaturesSection />
      <BenefitsSection />
      <ResourceHub />
      <DownloadInstructions />
      <FAQSection />
    </div>
  )
}

export default Homepage
