import React from 'react';
import { motion } from 'framer-motion';

const features = [
    {
        title: "Personalized Career Assessments",
        icon: "📊",
        description: "Discover your strengths and find the right career path tailored just for you."
    },
    {
        title: "Event Notifications",
        icon: "📅",
        description: "Stay updated with the latest school events and career fairs happening near you."
    },
    {
        title: "Job Opportunities",
        icon: "💼",
        description: "Connect with internships and job openings that match your skills and interests."
    }
];

const FeaturesSection = () => {
    return (
        <div className="container mx-auto px-4 py-10 bg-yellow-500 rounded-md">
            <h2 className="text-3xl font-bold text-center mb-6">App Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {features.map((feature, index) => (
                    <motion.div
                        key={index}
                        className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105"
                        initial={{ opacity: 0, y: 20 }} // Initial state
                        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5, delay: index * 0.2, ease: 'linear' } }} // Final state
                        whileHover={{ scale: 1.03, transition: { ease: 'easeInOut', duration: 0.3 } }}
                    >
                        <div className="text-5xl mb-4">{feature.icon}</div>
                        <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                        <p className="text-gray-600">{feature.description}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default FeaturesSection;