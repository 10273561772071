import React from 'react';
import { FaApple, FaAndroid } from 'react-icons/fa'; // Importing icons from react-icons

const DownloadInstructions = () => {
    return (
        <div className="container mx-auto px-4 py-10">
            <h2 className="text-3xl font-bold text-center mb-6">Download Instructions</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Android Section */}
                <div className=" rounded-lg shadow-lg p-6 flex flex-col items-center text-center bg-yellow-500">
                    <h3 className="text-xl font-semibold mb-4">For Android Users</h3>
                    <p className="text-gray-600 mb-4">
                        To download the Companion app directly to your Android device while we await our official launch on Google Play, please follow these steps:
                    </p>
                    <ol className="list-decimal list-inside text-left mb-4">
                        <li>Click the Android button below.</li>
                        <li>If prompted, go to your device settings and enable installations from unknown sources.</li>
                        <li>Once the APK file is downloaded, open it from your notifications or file manager to begin the installation.</li>
                        <li>After installation, open the Companion app from your app drawer and start exploring!</li>
                    </ol>
                    <a href="/companion.apk" download className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 no-underline hover:no-underline">
                        <FaAndroid className="inline mr-2" /> Download APK
                    </a>
                </div>

                {/* iOS Section */}
                <div className="bg-blue-900 rounded-lg shadow-lg p-6 flex flex-col items-center text-center text-white">
                    <h3 className="text-xl font-semibold mb-4">For iOS Users</h3>
                    <p className="text-gray-300 mb-4">
                        If you're using an iOS device, you can easily download the Companion app from the Apple App Store:
                    </p>
                    <ol className="list-decimal list-inside text-left mb-4">
                        <li>Click the Apple Store icon below.</li>
                        <li>You will be redirected to the iOS App Store page for Companion.</li>
                        <li>Tap "Get" or the download icon to install the app on your device.</li>
                        <li>Once installed, find the Companion app on your home screen and tap to open.</li>
                    </ol>
                    <a href="https://apps.apple.com/in/app/companion-app/id6738088705" target='_blank' rel="noreferrer" className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 no-underline hover:no-underline">
                        <FaApple className="inline mr-2" /> Open Apple Store
                    </a>
                </div>
            </div>

            {/* Notes Section */}
            <div className="mt-8 text-center text-gray-500">
                <p>Ensure you have a stable internet connection while downloading.</p>
                <p>If you encounter any issues during installation, please refer to our support page or contact customer service.</p>
            </div>
        </div>
    );
};

export default DownloadInstructions;