import React from 'react'
import AboutContent from '../components/about/AboutContent'

const AboutUs = () => {
  return (
    <div>
      <AboutContent/>
    </div>
  )
}

export default AboutUs
