import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const faqs = [
    {
        question: "Is it safe to download the APK file?",
        answer: "Yes, our APK file is safe to download. We ensure that all files are scanned for malware and other security threats."
    },
    {
        question: "How do I install the APK on my Android device?",
        answer: "To install the APK, download the file, then open it from your notifications or file manager. You may need to enable installations from unknown sources in your settings."
    },
    {
        question: "What should I do if I encounter issues during installation?",
        answer: "If you face any issues during installation, please refer to our support page or contact customer service for assistance."
    },
    {
        question: "Will my data be secure when using the app?",
        answer: "Absolutely! We prioritize user privacy and security. Your data is encrypted and stored securely."
    }
];

const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="container mx-auto p-6  bg-gray-50 mb-5">
            <h2 className="text-3xl font-bold text-center mb-6">Frequently Asked Questions</h2>
            <div className="space-y-6">
                {faqs.map((faq, index) => (
                    <div key={index} className="group">
                        <motion.div
                            className={`bg-white rounded-lg shadow-md border transition-all duration-300 ${activeIndex === index ? "border-blue-500" : "border-gray-200"
                                }`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div
                                className="flex justify-between items-center px-5 py-3 cursor-pointer hover:bg-blue-50"
                                onClick={() => toggleFAQ(index)}
                            >
                                <h3
                                    className={`text-lg font-medium transition-colors duration-300 ${activeIndex === index ? "text-blue-600" : "text-gray-800"
                                        }`}
                                >
                                    {faq.question}
                                </h3>
                                {activeIndex === index ? (
                                    <FaChevronUp className="text-blue-600" />
                                ) : (
                                    <FaChevronDown className="text-gray-400 group-hover:text-blue-600" />
                                )}
                            </div>
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={
                                    activeIndex === index
                                        ? { height: "auto", opacity: 1 }
                                        : { height: 0, opacity: 0 }
                                }
                                transition={{ duration: 0.3 }}
                                className="overflow-hidden"
                            >
                                <div className="px-5 py-3 text-gray-600">{faq.answer}</div>
                            </motion.div>
                        </motion.div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQSection;
