import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/image.png";

const MyNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`${scrolling ? "shadow-md" : ""} sticky top-0 w-full bg-white transition-all duration-300 z-[100]`}>
      <div className="container mx-auto flex justify-between items-center px-4">
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Company Logo" className="h-10" />
          </Link>
        </div>
        <div className="hamburger md:hidden" onClick={toggleMenu}>
          <button className="text-xl focus:outline-none">
            {isOpen ? "✖️" : "☰"}
          </button>
        </div>
        <ul className={`nav-links md:flex md:items-center transition-all duration-300 ${isOpen ? "block bg-blue-700 text-white" : "hidden bg-white"} md:block absolute md:static  w-full md:w-auto left-0 top-full md:top-auto md:bg-transparent`}>
          <li className="my-2 md:my-0 md:mx-4 text-center">
            <Link to="/about" className="nav-link text-gray-700 hover:text-blue-500 block p-2" onClick={() => setIsOpen(false)}>
              About Us
            </Link>
          </li>
          <li className="my-2 md:my-0 md:mx-4 text-center">
            <Link to="/contact" className="nav-link text-gray-700 hover:text-blue-500 block p-2" onClick={() => setIsOpen(false)}>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MyNavbar;