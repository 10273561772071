import React from 'react';
import { motion } from 'framer-motion';

const resources = [
    {
        title: "Blog Section",
        icon: "📝",
        description: "Read articles on topics like 'How to Choose the Right Career Path' or 'Top Tips for Attending Career Fairs.'"
    },
    {
        title: "Downloadable Resources",
        icon: "📚",
        description: "Access guides or e-books on resume writing, interview preparation, or networking strategies available in exchange for email sign-ups."
    },
    {
        title: "Webinars and Workshops (Upcomming)",
        icon: "🎓",
        description: "Join live sessions with industry experts to enhance your skills and knowledge."
    }
];

const ResourceHub = () => {
    return (
        <div className="container mx-auto px-4 py-10 bg-blue-900 rounded-md">
            <h2 className="text-3xl font-bold text-center mb-6 text-white">Resource Hub</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {resources.map((resource, index) => (
                    <motion.div
                        key={index}
                        className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105"
                        initial={{ opacity: 0, y: 20 }} // Initial state
                        whileInView={{
                            opacity: 1, y: 0, transition: {
                                duration: 0.3, // Duration of the animation
                                ease: 'easeIn', // Custom easing function for smoothness
                                delay: index * 0.2 // Staggered delay for each benefit} }} 
                            }
                        }}
                        whileHover={{ scale: 1.03, transition: { ease: 'easeInOut', duration: 0.3 } }}
                    >
                        <div className="text-5xl mb-4">{resource.icon}</div>
                        <h3 className="text-xl font-semibold mb-2">{resource.title}</h3>
                        <p className="text-gray-600">{resource.description}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default ResourceHub;